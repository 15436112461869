import { denormalizeData, filterRequiredParams } from 'core/utils/api';
import { topicsQuery } from 'core/queries/topics';
import Card1 from 'site/cards/Card1';

export default (fromList) => {
  const params = fromList ?
    { list: 'news', sort: 'list' } :
    { topic_type: 'news' };

  return topicsQuery({
    limit: 9,
    fields: filterRequiredParams([Card1], 'fields'),
    renderError: () => [],
    ...params,
  }, { select: denormalizeData });
};
