import { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'core/libs/helmet';

import {
  tagQuery,
  topicsQuery,
} from 'core/queries/tagTopics';

import useRequest from 'core/hooks/useRequest';
import PageLoader from 'core/components/Loader/PageLoader';

import TagTopics from 'core/components/TagTopics';

import { filterRequiredParams } from 'core/utils/api';

import { RUBRICS_PAGE_LIMIT } from 'site/constants';

import PageLayout from 'site/components/PageLayout';

import FooterAds from 'site/components/Ads/FooterAds';
import { SideIndent } from 'core/components/Wrappers';
import Headline from 'site/components/Headline';
import News from 'site/components/News';

import newsQuery from 'site/queries/news';

import Card2 from 'site/cards/Card2';

import SectionContent from './Content';

function TagPage({ location, history, match }) {
  const [
    { data: tag, isLoading: tagIsLoading },
    { data: topics, isLoading: topicsAreLoading },
    { data: news, isLoading: newsAreLoading },
  ] = useRequest({ queries: [
    tagQuery({ history, match }),
    topicsQuery({
      location,
      history,
      match,
      limit: RUBRICS_PAGE_LIMIT,
      include: filterRequiredParams([Card2], 'include'),
      fields: filterRequiredParams([Card2], 'fields'),
    }),
    newsQuery(),
  ] });

  if (tagIsLoading) return <PageLoader />;

  return (
    <Fragment>
      <PageLayout>
        <TagTopics
          tag={tag}
          topics={topics}
          isLoading={topicsAreLoading || newsAreLoading}
          limit={RUBRICS_PAGE_LIMIT}
          headerComponent={Headline}
        >
          {props => {
            // eslint-disable-next-line react/prop-types
            const { title, titleMeta } = props;

            return (
              <Fragment>
                <Helmet title={`#${titleMeta || title}`} />
                <News topics={news} isLoading={newsAreLoading} />
                <SectionContent {...props} isLoading={topicsAreLoading} />
              </Fragment>
            );
          }}
        </TagTopics>
      </PageLayout>
      <SideIndent>
        <FooterAds contextCount={2} />
      </SideIndent>
    </Fragment>
  );
}

TagPage.propTypes = {
  location: PropTypes.object,
  match: PropTypes.object,
  history: PropTypes.object,
};

export default TagPage;
