import PropTypes from 'prop-types';

import withTopic from 'core/components/Topic/withTopic';
import { withBreakpoint } from 'core/components/breakpoint';
import RandomizedRender from 'core/components/RandomizedRender';

import { Indent } from 'core/components/Wrappers';

import { PartnersHorizontal, Recommender } from 'site/components/Partners';

import { VERTICAL_INDENT, VERTICAL_INDENT_MOBILE } from 'site/constants';

function Boroda({ isDesktop, topic }) {
  if (!topic) {
    return null;
  }

  const spacing = isDesktop ? VERTICAL_INDENT : VERTICAL_INDENT_MOBILE;
  const { infinityIndex } = topic || {};

  return (
    <Indent top={spacing} >
      {infinityIndex % 2 !== 0 ? (
        <RandomizedRender probabilities={[50, 50]}>
          <Recommender />
          <PartnersHorizontal />
        </RandomizedRender>
      ) : (
        <PartnersHorizontal />
      )}
    </Indent>
  );
}

Boroda.propTypes = {
  isDesktop: PropTypes.bool,
  topic: PropTypes.object,
};

export default withBreakpoint(withTopic(Boroda));
