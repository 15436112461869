import PropTypes from 'prop-types';

import Feed from 'core/components/Feed';
import CompareCard from 'site/cards/CompareCard';
import { Indent } from 'core/components/Wrappers';
import PageLoader from 'core/components/Loader/PageLoader';

function GameCompareRubric({ topics, isDesktop, isLoading }) {
  if (isLoading) return <PageLoader />;
  if (!topics?.length) return null;

  return (
    <Indent top={20}>
      <Feed
        content={topics}
        card={CompareCard}
        interitemSpacing={isDesktop ? 30 : 20}
      />
    </Indent>
  );
}

GameCompareRubric.propTypes = {
  topics: PropTypes.array,
  isDesktop: PropTypes.bool,
  isLoading: PropTypes.bool,
};

export default GameCompareRubric;
