import { Fragment } from 'react';

import Link from 'core/components/Link';
import Button from 'core/components/Button';
import Comments from 'core/components/Comments';
import Topic from 'core/components/Topic';
import useRequest from 'core/hooks/useRequest';
import { topicQuery } from 'core/queries/topic';
import { Indent, SideIndent, TopicContentMaxWidth } from 'core/components/Wrappers';
import PageLoader from 'core/components/Loader/PageLoader';

import TopicWrapper from 'site/pages/topic/TopicWrapper';
import TopicHeader from 'site/pages/topic/TopicHeader';

import FooterAds from 'site/components/Ads/FooterAds';


function CommentsPage(props) {
  const { data, isLoading } = useRequest(topicQuery(props));

  return (
    <Fragment>
      {isLoading ? (
        <PageLoader />
      ) : (
        <Topic content={data}>
          {topic => {
            const {
              id,
              attributes: {
                link,
                is_comments_disabled: isCommentsDisabled,
                is_premium: isPremium,
              },
            } = topic;

            return (
              <TopicWrapper content={topic} comments>
                {!isPremium && (
                  <Indent bottom={20}>
                    <TopicHeader
                      hideCover
                      content={topic}
                      comments
                    />
                  </Indent>
                )}
                <TopicContentMaxWidth>
                  <Indent bottom={20}>
                    <Link to={link} type='secondary'>
                      <Button>Вернуться к статье</Button>
                    </Link>
                  </Indent>
                  <Comments
                    adv
                    advInterval={4}
                    xid={id}
                    isCommentsDisabled={isCommentsDisabled}
                  />
                </TopicContentMaxWidth>
              </TopicWrapper>
            );
          }}
        </Topic>
      )}

      <SideIndent>
        <FooterAds contextCount={2} />
      </SideIndent>
    </Fragment>
  );
}

export default CommentsPage;
