import { useContext } from 'react';

import ContentBackground from 'core/components/ContentBackground';
import Shapka, { StickyContainer } from 'core/components/Shapka';
import Layout, { Header, Content, Footer } from 'core/components/Layout';
import { Desktop } from 'core/components/breakpoint';
import { GlobalTopicContext } from 'core/components/GlobalTopicProvider';

import CookiesBanner from 'core/components/CookiesBanner';

import Scooter from '../Scooter';

import { Billboard } from '../Ads/desktop';

import styles from './index.styl';


function SiteLayout({ children }) {
  const topic = useContext(GlobalTopicContext);
  const isPremiumTopic = topic?.attributes?.is_premium;

  return (
    <StickyContainer>
      <ContentBackground>
        <Layout>
          <Header>
            <Shapka isSecondShapkaForPremium={isPremiumTopic} fullwidth={isPremiumTopic} />
          </Header>
          <Content>
            {!isPremiumTopic && (
              <Desktop>
                <Billboard />
              </Desktop>
            )}
            <div className={styles.content}>
              {children}
            </div>
          </Content>
          <Footer>
            <Scooter />
          </Footer>
          <CookiesBanner />
        </Layout>
      </ContentBackground>
    </StickyContainer>
  );
}

export default SiteLayout;
