import color from 'core/libs/color';

import deepmerge from 'core/libs/deepmerge';
import bindProps from 'core/components/bindProps';

import { NegativeMobile, TopicContentMaxWidth } from 'core/components/Wrappers';

import Video from 'site/icons/Video';
import Photo from 'site/icons/Photo';
import Burger from 'site/icons/Burger';
import ArrowLeft from 'site/icons/ArrowLeft.svg';
import ArrowRight from 'site/icons/ArrowRight.svg';
import DefaultAvatar from 'site/icons/DefaultAvatar.svg';
import Winner from 'site/icons/Winner.svg';

import {
  VERTICAL_INDENT,
  SIDE_INDENT,
  CONTENT_AREA,
} from 'site/constants';

import Logo from 'site/components/Logo';
import errorImg from 'site/pages/images/error.png';
import notFoundImg from 'site/pages/images/notFound.jpg';
import Card from 'site/cards/Card2';
import CompareCard from 'site/cards/CompareCard';

import fonts from './fonts';


const Card2Type2 = bindProps({ type: 2 })(Card);

const FooterLogo = bindProps({ type: 1 })(Logo);

const icons = {
  logo: Logo,
  Video,
  Photo,
  burger: Burger,
  arrowLeft: ArrowLeft,
  arrowRight: ArrowRight,
  DefaultAvatar,
};

export default function baseTheme(atoms) {
  const animations = {
    hover: '150ms ease-out',
  };

  const texts = {
    lead: {
      font: `24px/35px ${fonts.nuance}`,
      color: color(atoms.colors.primary).alpha(0.9).string(),
      marginTop: '20px',
    },

    body: {
      font: `20px/30px ${fonts.text}`,
      color: atoms.colors.primary,
    },

    title1: {
      font: `800 40px/48px ${fonts.display}`,
      color: atoms.colors.primary,
    },

    title2: {
      font: `700 28px/34px ${fonts.nuance}`,
      color: atoms.colors.primary,
    },

    title3: {
      font: `700 24px/28px ${fonts.nuance}`,
      color: atoms.colors.primary,
    },

    title4: {
      font: `700 20px/26px ${fonts.nuance}`,
      color: atoms.colors.primary,
    },

    title5: {
      font: `700 20px/26px ${fonts.nuance}`,
      color: atoms.colors.primary,
    },

    title6: {
      font: `700 20px/30px ${fonts.nuance}`,
      color: atoms.colors.primary,
    },

    caption1: {
      font: `16px/24px ${fonts.nuance}`,
      color: atoms.colors.primary,
    },

    caption3: {
      font: `14px/18px ${fonts.nuance}`,
      color: atoms.colors.hint,
    },

    quote: {
      font: `700 20px/30px ${fonts.display}`,
      color: atoms.colors.primary,
    },

    ordinalNumber: {
      font: `400 48px/50px ${fonts.text}`,
      color: atoms.colors.primary,
    },

    topicInfo: { // Info, Date, Rubric
      font: `700 14px/16px ${fonts.display}`,
      color: atoms.colors.placeholder,
    },

    author: {
      font: `16px/20px ${fonts.nuance}`,
      color: atoms.colors.primary,
    },

    authorSmall: {
      font: `14px/18px ${fonts.nuance}`,
      color: atoms.colors.placeholder,
    },

    imageDescription: {
      font: `14px/18px ${fonts.text}`,
      color: atoms.colors.hint,
    },

    imageCopyright: {
      font: `16px/22px ${fonts.nuance}`,
      color: atoms.colors.hint,
    },
  };

  const smartTeaser = {
    carRelease: {
      title: {
        font: `700 22px/24px ${fonts.nuance}`,
      },
      info: {
        font: `400 16px/26px ${fonts.nuance}`,
      },
      link: {
        borderBottom: `1px ${atoms.colors.active1} solid`,
        color: atoms.colors.active1,
      },
    },
  };

  const link = {
    article: {
      idle: {
        color: atoms.colors.active1,
        decoration: 'none',
      },

      hover: {
        color: atoms.colors.primary,
        decoration: 'none',
      },

      active: {
        color: atoms.colors.primary,
        decoration: 'none',
      },

      visited: {
        color: atoms.colors.active1,
        decoration: 'none',
      },
    },

    secondary: {
      idle: {
        color: atoms.colors.active1,
        decoration: 'none',
      },

      hover: {
        color: color(atoms.colors.active1).mix(color(atoms.colors.primary), 0.3).string(),
        decoration: 'none',
      },

      active: {
        color: atoms.colors.active1,
        decoration: 'none',
      },

      visited: {
        color: atoms.colors.active1,
        decoration: 'none',
      },
    },
  };

  const sizes = {
    small: {
      font: `14px/18px ${fonts.nuance}`,
      width: '140px',
      height: '30px',
      padding: '6px 12px',
    },
    medium: {
      font: `700 14px/16px ${fonts.nuance}`,
      width: '150px',
      height: '40px',
      padding: '11px 30px 12px',
    },

    large: {
      font: `700 16px/16px ${fonts.nuance}`,
      width: '180px',
      height: '52px',
      padding: '10px 30px',
    },

    extraLarge: {
      font: `14px/17px ${fonts.nuance}`,
      width: '180px',
      height: '40px',
      padding: '11px 24px',
    },
  };

  const button = {
    const: {
      sizes,
      radius: '3px',
    },

    secondary: {
      idle: {
        color: atoms.colors.primary,
        background: 'none',
        border: `1px solid ${atoms.colors.divider}`,
      },

      hover: {
        color: atoms.colors.active1,
        background: 'none',
        border: `1px solid ${atoms.colors.active1}`,
      },

      active: {
        color: atoms.colors.active1,
        background: 'none',
        border: `1px solid ${atoms.colors.active1}`,
      },

      disabled: {
        color: color(atoms.colors.primary).alpha(0.4).string(),
        background: 'none',
        border: `1px solid ${color(atoms.colors.divider).alpha(0.4).string()}`,
      },
    },

    secondaryDark: {
      idle: {
        color: atoms.colors.primary,
        background: atoms.colors.content,
        border: `1px solid ${atoms.colors.primary}`,
      },

      hover: {
        color: atoms.colors.primary,
        background: atoms.colors.content,
        border: `1px solid ${atoms.colors.primary}`,
      },

      active: {
        color: atoms.colors.primary,
        background: atoms.colors.content,
        border: `1px solid ${atoms.colors.primary}`,
      },

      disabled: {
        color: atoms.colors.primary,
        background: atoms.colors.content,
        border: `1px solid ${atoms.colors.primary}`,
      },
    },
  };

  const shapkaLink = {
    padding: '5px 17px 0',
    font: `700 16px/26px ${fonts.nuance}`,
  };

  const shapka = {
    Burger: icons.burger,

    link: shapkaLink,
    dropdown: {
      height: '75px',
      link: shapkaLink,
      premiumTopic: {
        link: {
          ...shapkaLink,
          padding: '6px 20px 6px 0',
        },
      },
    },
    height: 89,
    padding: `15px ${SIDE_INDENT}px 8px`,
    logo: {
      Icon: Logo,
      width: 270,
      height: 29,
      marginTop: '-5px',
    },
    labelNoAds: {
      fontWeight: 700,
      color: '#fff',
      textTransform: 'none',
    },
    menu: {
      navMargin: '0 0 0 50px',
    },
  };

  const scooter = {
    background: atoms.colors.active2,
    padding: `0 ${SIDE_INDENT}px`,
    social: {
      margin: '0 0 0 20px',
      size: 34,
      iconSize: 20,
      borderRadius: 5,
      idle: {
        color: atoms.colors.primary,
        background: atoms.colors.active2,
        border: 'none',
      },
      hover: {
        color: atoms.colors.active2,
        background: atoms.colors.primary,
        border: 'none',
      },
    },
    logo: {
      Icon: FooterLogo,
      width: '176px',
      height: '19px',
    },
    menuLink: {
      font: `700 16px/26px ${fonts.nuance}`,
      hover: {
        color: atoms.colors.primary,
        decoration: 'underline',
      },
      active: {
        color: atoms.colors.primary,
      },
      current: {
        color: atoms.colors.primary,
        decoration: 'underline',
      },
    },
  };

  const gallery = {
    slide: {
      background: atoms.colors.placeholder,
    },
    icons: {
      background: atoms.colors.galleryIconsBackground,
    },
  };

  const counter = {
    font: texts.imageCopyright.font,
    color: atoms.colors.primary,
  };

  const vikontImage = {
    maxWidth: CONTENT_AREA,
    maxHeight: 3000,
  };

  const input = {
    const: {
      sizes,
      color: atoms.colors.primary,
    },

    basic: {
      idle: {
        border: `1px solid ${atoms.colors.hint}`,
        placeholderColor: atoms.colors.hint,
      },

      hover: {
        border: `1px solid ${atoms.colors.hint}`,
      },

      focused: {
        border: `1px solid ${atoms.colors.primary}`,
      },
    },
  };

  const paginatron = {
    text: {
      font: `18px/24px ${fonts.nuance}`,
      color: atoms.colors.primary,
    },

    icon: {
      arrow: {
        size: '18px',
      },

      doubleArrow: {
        size: '18px',
      },
    },

    radius: button.const.radius,
    outerButtons: {
      innerBorder: button.secondary.idle.border,
      hover: {
        background: button.secondary.hover.background,
        color: button.secondary.hover.color,
      },
    },
  };

  const commentsPreview = {
    headline: {
      font: `700 28px/1 ${fonts.nuance}`,
      color: atoms.colors.hint,
    },
    name: {
      font: `700 16px/20px ${fonts.nuance}`,
    },
    date: {
      font: `normal 12px/16px ${fonts.nuance}`,
    },
    comment: {
      font: `normal 16px/20px ${fonts.nuance}`,
    },
  };

  const layout = {
    sideColumnIndent: '30px',
    contentColumnMaxWidth: '834px',
    indents: {
      top: VERTICAL_INDENT,
      right: SIDE_INDENT,
      bottom: VERTICAL_INDENT,
      left: SIDE_INDENT,
    },
  };

  const mark = {
    padding: '0.2em',
    background: '#F2C8C0',
  };

  const topicContent = {
    widgetsMaxWidth: 618,
    widgetsWithNegativeMobileIndent: {
      'opinion': true,
      'number': true,
      'smartTeaser': true,
    },
    tableOfContents: {
      maxWidht: '618px',
      margin: '0 auto 30px',
    },
  };

  const author = {
    types: {
      expert: {
        background: atoms.colors.active1,
        color: atoms.colors.content,
        borderRadius: '2px',
        textTransform: 'none',
        border: 'none',
      },
    },
  };

  const ads = {
    urlPreviewImage: {
      retina: require('site/pages/images/prime/macbookPreview-2x.png'),
      common: require('site/pages/images/prime/macbookPreview-1x.png'),
    },
  };

  const colors = {
    adsColor: '#fff',
  };

  const personal = {
    circleCheckMark: {
      stroke: '#fff',
    },
    profileAvatar: {
      isCircular: true,
    },
  };

  const topicHeaderTexts = {
    title: {
      font: `800 40px/48px ${fonts.display}`,
      letterSpacing: 'normal',
      color: atoms.colors.primary,
    },
    subtitle: {
      font: `500 36px/50px ${fonts.display2}`,
      letterSpacing: 'normal',
      color: color(atoms.colors.primary).alpha(0.9).string(),
    },
    lead: { ...texts.lead },
  };

  const topicFooter = {
    commentButton: {
      type: 'primary',
      font: `700 20px/24px ${fonts.nuance}`,
      topSpacing: VERTICAL_INDENT,
      size: 'medium',
    },
    socialMedia: {
      type: 'fancy',
    },
    links: {
      type: 'secondary',
      borderBottom: `1px ${atoms.colors.active1} solid`,
      hover: {
        borderBottom: '1px transparent solid',
      },
    },
    block: {
      spacing: VERTICAL_INDENT,
    },
  };

  const widgetCaption = {
    caption: {
      font: texts.imageCopyright.font,
      color: atoms.colors.primary,
    },
    credits: {
      font: texts.imageCopyright.font,
      color: atoms.colors.hint,
    },
  };

  const topicTopline = {
    font: `14px/1 ${fonts.nuance}`,
    color: color(atoms.colors.primary).alpha(0.4).string(),
    rubric: {
      font: `700 14px/1 ${fonts.nuance}`,
      textTransform: 'uppercase',
      color: atoms.colors.active1,
      letterSpacing: '0.2em',
      border: 'none',
      borderRadius: '0',
      padding: '0',
      after: {
        content: ' /',
      },
      hover: {
        color: atoms.colors.active1,
        background: 'transparent',
      },
      premiumTopic: {
        color: '#fc8c76',
      },
    },
  };

  const subtitle = (
    <div style={{ color: atoms.colors.hint }}>Мы уже знаем об этом и скоро всё исправим</div>
  );

  const errorPage = {
    background: 'transparent',
    imgWidth: 540,
    imgUrl: notFoundImg,
    title: {
      font: `bold 56px/56px ${fonts.display}`,
    },
    subtitle: {
      font: `36px/49px ${fonts.text}`,
    },
    card: Card2Type2,
  };

  const pages = {
    error: errorPage,
    notFound: errorPage,
    sentryFallback: {
      title: {
        name: 'Что-то пошло не так',
        font: `bold 36px/48px ${fonts.nuance}`,
      },
      subtitle: {
        name: subtitle,
        font: `14px/18px ${fonts.nuance}`,
      },
      imgUrl: errorImg,
      imgWidth: 45,
      card: null,
    },
  };

  const vikontReadMore = {
    block: {
      background: atoms.colors.layout,
      padding: '20px 30px',
    },
    title: {
      color: color(atoms.colors.primary).alpha(0.5).string(),
      font: `400 14px/17px ${fonts.nuance}`,
      letterSpacing: '0.08em',
    },
    drum: {
      arrowsShiftVertical: 77,
      itemWidth: '45%',
      spaceBetween: 20,
    },
    singleCard: {
      alignItems: 'center',
    },
  };

  const expert = {
    name: {
      idle: {
        font: `700 18px/27px ${fonts.nuance}`,
        margin: '0',
      },
      hover: {
        font: `700 18px/27px ${fonts.nuance}`,
        margin: '0',
      },
    },
    jobTitle: {
      font: `400 15px/21px ${fonts.nuance}`,
    },
    label: {
      background: atoms.colors.primary,
      font: `700 10px/1 ${fonts.nuance}`,
      letterSpacing: '0.01em',
      borderRadius: '0 0 5px 5px',
    },
  };

  const drum = {
    arrow: {
      fade: {
        outer: 'transparent',
        inner: 'transparent',
      },
    },
  };

  const notes = {
    background: atoms.colors.placeholder,
    headlineFontFamily: fonts.nuance,
  };

  const gameCompare = {
    showComments: true,
    compareCard: {
      progressbar: {
        background: atoms.colors.black,
        strokeColor: atoms.colors.black,
      },
    },
    progressbar: {
      progressInside: true,
      separationText: '/',
      textColor: atoms.colors.black,
      strokeColor: 'transparent',
      progressColor: atoms.colors.active1,
      font: `10px/14px ${fonts.display}`,
    },
    announce: {
      font: `24px/38px ${fonts.nuance}`,
      announceIndent: TopicContentMaxWidth,
    },
    compare: {
      progressType: 3,
      caption: {
        color: atoms.colors.content,
        background: atoms.colors.black,
      },
      credits: {
        color: atoms.colors.hint,
        font: `400 14px/18px ${fonts.display}`,
      },
      text: {
        background: atoms.colors.placeholder,
      },
    },
    tooltip: {
      theme: 'dark',
      buttonType: 'secondaryDark',
      scheme: {
        innerColor: '#FFEA00',
        outerColor: atoms.colors.black,
        titleFont: `700 20px/33px ${fonts.nuance}`,
        textFont: `18px/26px ${fonts.nuance}`,
      },
      info: {
        background: atoms.colors.hint,
        hover: {
          background: atoms.colors.black,
        },
      },
    },
    share: {
      color: atoms.colors.primary,
      font: `700 18px/27px ${fonts.nuance}`,
      marginTop: '50px',
    },
    leaderBoard: {
      card: CompareCard,
      table: {
        borderColor: '#d9d9d9',
        background: atoms.colors.content,
        activeBackground: atoms.colors.placeholder,
        indent: NegativeMobile,
      },
      header: {
        color: '#5e5e5e',
        font: `400 12px/14px ${fonts.nuance}`,
        questionBackground: atoms.colors.placeholder,
        questionFill: '#929292',
      },
      count: {
        background: atoms.colors.placeholder,
        font: `700 15px/18px ${fonts.display}`,
        activeBackground: atoms.colors.content,
        activeColor: atoms.colors.primary,
      },
      progressbar: {
        height: '1px',
        progressHeight: '3px',
        background: '#b3b3b3',
        defaultProgress: atoms.colors.primary,
        activeBackground: '#a9a9a9',
        font: `300 12px/17px ${fonts.nuance}`,
      },
      modalWindow: {
        iconFill: '#777777',
        title: `600 18px/30px ${fonts.text}`,
      },
      caption: `400 16px/20px ${fonts.nuance}`,
      ratings: `700 18px/20px ${fonts.nuance}`,
    },
    concidences: {
      font: `400 20px/29px ${fonts.nuance}`,
      margin: '0 0 15px',
      highlight: false,
    },
    winner: {
      icon: Winner,
      background: atoms.colors.active1,
      borderRadius: '8px',
      imageTop: '30px',
      strokeColor: atoms.colors.content,
      padding: '30px',
      fillColor: atoms.colors.content,
      viewBox: '0 0 870 580',
      iconStep: 120,
    },
  };

  const profile = {
    name: {
      font: `600 16px/20px ${fonts.nuance}`,
    },
    email: {
      font: `400 12px/14px ${fonts.nuance}`,
    },
    logout: {
      font: `600 14px/17px ${fonts.nuance}`,
    },
    description: {
      font: `500 14px/17px ${fonts.nuance}`,
    },
  };

  const reactions = {
    tiny: {
      font: `700 13px/15px ${fonts.nuance}`,
    },
  };

  const reactionsEmojiBar = {
    const: {
      padding: '24px 25px',
      borderRadius: '5px',
      background: atoms.colors.papaya,
      border: 'none',
      boxShadow: `2px 2px 0px 0px ${color(atoms.colors.papayaDark).alpha(0.36)}`,
    },

    title: {
      font: `700 20px/22px ${fonts.nuance}`,
    },
  };

  const login = {
    popup: {
      button: {
        font: `600 16px/19px ${fonts.nuance}`,
      },
      content: {
        font: `400 12px/18px ${fonts.nuance}`,
      },
    },
    modal: {
      button: {
        font: `600 16px/21px ${fonts.nuance}`,
        color: atoms.colors.content,
      },
      content: {
        font: `400 16px/23px ${fonts.nuance}`,
        color: atoms.colors.black,
      },
    },
  };

  const advTooltip = {
    font: `400 12px/15px ${fonts.nuance}`,
  };

  const cookiesBanner = {
    text: {
      font: `400 13px/18px ${fonts.nuance}`,
    },
    button: {
      btnText: `600 16px/18px ${fonts.text}`,
    },
  };

  return deepmerge({
    fonts,
    icons,
    texts,
    animations,
    controls: {
      topicHeaderTexts,
      input,
      counter,
      smartTeaser,
      link,
      drum,
      button,
      shapka,
      scooter,
      gallery,
      vikontImage,
      vikontReadMore,
      paginatron,
      commentsPreview,
      topicContent,
      topicTopline,
      topicFooter,
      author,
      personal,
      widgetCaption,
      expert,
      notes,
      mark,
      gameCompare,
      profile,
      reactions,
      reactionsEmojiBar,
      login,
      advTooltip,
      cookiesBanner,
    },
    layout,
    colors,
    ads,
    pages,
  }, atoms);
}
