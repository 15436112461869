import { Fragment } from 'react';
import PropTypes from 'prop-types';

import useRequest from 'core/hooks/useRequest';
import { topicQuery } from 'core/queries/topic';
import Topic from 'core/components/Topic';
import LeaderBoardCore from 'core/components/GameCompare/LeaderBoard';
import { SideIndent, TopicContentMaxWidth } from 'core/components/Wrappers';
import FooterAds from 'site/components/Ads/FooterAds';

import PageLoader from 'core/components/Loader/PageLoader';
import withBreakpoint from 'core/components/breakpoint/withBreakpoint';
import resolveRelationships from 'core/utils/relationships';

import Indent from 'core/components/Wrappers/Indent';

import TopicWrapper from 'site/pages/topic/TopicWrapper';
import TopicHeader from 'site/pages/topic/TopicHeader';


const relationships = resolveRelationships(['content'], {});

function LeaderBoard(props) {
  const { isMobile } = props;
  const { data, isLoading } = useRequest(topicQuery(props));

  return (
    <Fragment>
      {isLoading ? (
        <PageLoader />
      ) : (
        <Topic content={data}>
          {topic => {
            const {
              content: {
                widgets,
              },
            } = relationships(topic);

            if (widgets.length === 0) return null;

            const {
              final_announce: finalAnnounce,
            } = widgets[0].attributes;

            return (
              <TopicWrapper>
                <Indent top={isMobile ? 16 : 20} bottom={20}>
                  <TopicHeader content={topic} isGameCompare />
                </Indent>
                <TopicContentMaxWidth>
                  <div>{finalAnnounce}</div>
                </TopicContentMaxWidth>
                <Indent top={25} bottom={isMobile ? 80 : 50}>
                  <LeaderBoardCore content={topic} />
                </Indent>
              </TopicWrapper>
            );
          }}
        </Topic>
      )}

      <SideIndent>
        <FooterAds contextCount={2} />
      </SideIndent>
    </Fragment>
  );
}

LeaderBoard.propTypes = {
  isMobile: PropTypes.bool,
};

export default withBreakpoint(LeaderBoard);
