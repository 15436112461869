import PropTypes from 'prop-types';
import { Fragment } from 'react';

import {
  rawRubricQuery,
  rawTopicsQuery,
} from 'core/queries/rubric';

import useRequest from 'core/hooks/useRequest';

import { filterRequiredParams } from 'core/utils/api';

import Rubric from 'core/components/Rubric';
import { isGameCompare } from 'core/components/GameCompare/utils';
import { SideIndent } from 'core/components/Wrappers';

import FooterAds from 'site/components/Ads/FooterAds';
import PageLayout from 'site/components/PageLayout';
import Headline from 'site/components/Headline';
import News from 'site/components/News';
import { VOID_QUERY } from 'core/site/constants';

import { RUBRICS_PAGE_LIMIT } from 'site/constants';

import newsQuery from 'site/queries/news';

import Card2 from 'site/cards/Card2';
import Card3 from 'site/cards/Card3';
import Card4 from 'site/cards/Card4';
import CompareCard from 'site/cards/CompareCard';

import SectionContent from './Content';
import SectionNewsContent from './News';
import GameCompareRubric from './GameCompareRubric';


function RubricPage({ match, location, history }) {
  const {
    params: {
      level_1: level1,
      level_2: level2,
    },
  } = match;

  const isNews = level1 === 'news';
  const newsNeeded = level2 !== 'ya-tak-zhivu' && !isGameCompare(location.pathname);
  const [
    { data: rubric, isLoading: rubricIsLoading },
    { data: topics, isLoading: topicsAreLoading },
  ] = useRequest({ queries: [
    rawRubricQuery({ match, history }),
    rawTopicsQuery({
      location,
      match,
      history,
      limit: RUBRICS_PAGE_LIMIT,
      include: filterRequiredParams([Card2, Card3, Card4, CompareCard], 'include'),
      fields: filterRequiredParams([Card2, Card3, Card4, CompareCard], 'fields'),
      topic_type: isNews ? 'news' : '-news',
    }),
  ] });

  const { data: news = [], isLoading: newsAreLoading } = useRequest(newsNeeded ? newsQuery() : VOID_QUERY);

  return (
    <Fragment>
      <PageLayout>
        <Rubric
          rawRubric={rubric}
          rawTopics={topics}
          isLoading={topicsAreLoading || rubricIsLoading || newsAreLoading}
          titleComponent={Headline}
          limit={RUBRICS_PAGE_LIMIT}
        >
          {props => {
            if (isNews) {
              return <SectionNewsContent {...props} />;
            }

            const isGameComparePage = isGameCompare(location.pathname);

            return (
              <Fragment>
                <News topics={news} isLoading={newsAreLoading} />
                {isGameComparePage && <GameCompareRubric {...props} isLoading={topicsAreLoading} />}

                {!isGameComparePage && (
                  <SectionContent
                    {...props}
                    isLoading={topicsAreLoading}
                    cardsForRubric
                  />
                )}
              </Fragment>
            );
          }}
        </Rubric>
      </PageLayout>
      <SideIndent>
        <FooterAds contextCount={2} />
      </SideIndent>
    </Fragment>
  );
}

RubricPage.propTypes = {
  location: PropTypes.object,
  match: PropTypes.shape({
    params: PropTypes.shape({
      level_1: PropTypes.string,
      level_2: PropTypes.string,
    }).isRequired,
  }).isRequired,
  history: PropTypes.object,
};


export default RubricPage;
